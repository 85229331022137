exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-4-reasons-facebook-went-down-so-badly-last-week-index-jsx": () => import("./../../../src/pages/blog/4-reasons-facebook-went-down-so-badly-last-week/index.jsx" /* webpackChunkName: "component---src-pages-blog-4-reasons-facebook-went-down-so-badly-last-week-index-jsx" */),
  "component---src-pages-blog-suspicious-prefix-announcements-or-ris-peer-malfunction-index-jsx": () => import("./../../../src/pages/blog/suspicious-prefix-announcements-or-ris-peer-malfunction/index.jsx" /* webpackChunkName: "component---src-pages-blog-suspicious-prefix-announcements-or-ris-peer-malfunction-index-jsx" */),
  "component---src-pages-blog-suspicious-route-against-a-root-dns-prefix-index-jsx": () => import("./../../../src/pages/blog/suspicious-route-against-a-root-dns-prefix/index.jsx" /* webpackChunkName: "component---src-pages-blog-suspicious-route-against-a-root-dns-prefix-index-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-docs-jsx": () => import("./../../../src/pages/docs.jsx" /* webpackChunkName: "component---src-pages-docs-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-opensource-jsx": () => import("./../../../src/pages/opensource.jsx" /* webpackChunkName: "component---src-pages-opensource-jsx" */),
  "component---src-pages-platform-jsx": () => import("./../../../src/pages/platform.jsx" /* webpackChunkName: "component---src-pages-platform-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-research-jsx": () => import("./../../../src/pages/research.jsx" /* webpackChunkName: "component---src-pages-research-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("./../../../src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */)
}

